import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts";
import Button from "../elements/button";
import Header from "../modules/header";
import ImageHelper from "../helpers/image";
import PropTypes from "prop-types";
import {
  formatVotes,
  getVoteTotals,
  submitVote,
  persistVoteTotal,
} from "../helpers/photo-comp";
import { Heart, HeartFilled } from "../helpers/icons";
import { copyToClipboard } from "../helpers/clipboard";

export const query = graphql`
  query PhotoCompEntryTemplateQuery($id: String!) {
    entry: sanityPhotoCompEntry(id: { eq: $id }) {
      _id
      path
      caption
      firstName
      lastName
      breadcrumbs {
        title
        slug
      }
      image {
        asset {
          _id
          altText
          metadata {
            lqip
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
        hotspot {
          height
          width
          x
          y
        }
      }
    }
    site: site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const PhotoCompEntryTemplate = props => {
  const { data } = props;
  const entry = data.entry;

  const [votes, setVotes] = React.useState(null);
  const [voted, setVoted] = React.useState(false);

  React.useEffect(() => {
    getVotes();
  }, []);

  const getVotes = async () => {
    const totals = await getVoteTotals();
    setVotes(entry._id in totals ? totals[entry._id] : 0);
  };

  const onVoteClick = async () => {
    setVoted(true);
    const result = await submitVote(entry._id);
    if (result === true) {
      const newVotes = votes + 1;
      setVotes(newVotes);
      await persistVoteTotal(entry._id, newVotes);
    } else {
      if (typeof window !== "undefined") {
        window.alert(result);
      }
    }
  };

  const entryUrl = `${data.site.siteMetadata.siteUrl}${entry.path}`;
  const facebookShareUrl = `https://www.facebook.com/sharer.php?u=${entryUrl}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    entryUrl
  )}`;

  return (
    <Layout className="p-calendar-comp-entry">
      <Header
        backgroundColour={"beige"}
        breadcrumbs={entry.breadcrumbs}
        text={`${entry.firstName} ${entry.lastName}`}
        title={entry.caption}
      />
      <div className="container">
        <div className="p-calendar-comp-entry-controls">
          <span className="votes">
            {votes === null ? "" : formatVotes(votes)}
          </span>
          <button
            className="e-button -primary"
            disabled={voted}
            onClick={onVoteClick}
          >
            <span>Vote{voted && "d"}</span>
            {voted ? (
              <HeartFilled className="e-link-icon" />
            ) : (
              <Heart className="e-link-icon" />
            )}
          </button>
        </div>
        <div className="p-calendar-comp-entry-photo">
          <div className="e-image-container">
            <ImageHelper image={entry.image} objectFit="contain" />
          </div>
        </div>
        <div className="p-calendar-comp-entry-share">
          <h3 className="subtitle">Share Image</h3>
          <button
            className={`e-button -tertiary`}
            onClick={e => copyToClipboard(e, entryUrl)}
          >
            Copy url
          </button>
          <Button link={facebookShareUrl} text="Facebook" type="tertiary" />
          <Button link={twitterShareUrl} text="Twitter" type="tertiary" />
        </div>
      </div>
    </Layout>
  );
};

PhotoCompEntryTemplate.propTypes = {
  data: PropTypes.object,
};

export default PhotoCompEntryTemplate;
